<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="editInvoiceItemTemplateForm"
        ref="editInvoiceItemTemplateForm"
        class="pt-4"
        @submit.prevent="submitForm"
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="invoiceItemTemplate.id"
              v-bind="textFieldAttrs"
              placeholder="-"
              disabled
              label="Nr usługi"
              class="mb-4"
              readonly
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="invoiceItemTemplate.name"
              v-bind="textFieldAttrs"
              placeholder="Wpisz nazwę usługi"
              label="Nazwa usługi"
              class="mb-4"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="invoiceItemTemplate.netValue"
              v-bind="textFieldAttrs"
              placeholder="Wpisz wartość netto"
              label="Wartość netto"
              validate-on-blur
              hide-details
              :rules="[rules.required, rules.money, rules.lowerThan(10000001)]"
              type="number"
              class="mb-4"
              @wheel="$event.target.blur()"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="invoiceItemTemplate.vatPercentage"
              v-bind="textFieldAttrs"
              placeholder="Wpisz stawkę vat"
              label="Stawka vat"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="editInvoiceItemTemplateForm"
      >
        Edytuj usługę
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import rules from '../../utils/validators'
import { mapState, mapActions } from 'vuex'
import { parseAsBasicUnit } from '../../utils'

export default {
  components: {
    DialogFormWrapper
  },
  data() {
    return {
      textFieldAttrs: {
        outlined: true,
        hideDetails: true,
        rules: [rules.required]
      },
      invoiceItemTemplate: {
        id: null,
        name: '',
        netValue: 0,
        vatPercentage: 0
      },
      rules
    }
  },
  mounted() {
    this.invoiceItemTemplate = { ...this.dialogItem, netValue: this.dialogItem.netValue / 100 }
  },
  methods: {
    ...mapActions({
      editInvoiceItemTemplate: 'invoiceItemTemplates/editItem',
      getInvoiceItemTemplates: 'invoiceItemTemplates/getItems',
      closeDialog: 'layout/closeDialog'
    }),
    submitForm() {
      if (this.$refs.editInvoiceItemTemplateForm.validate()) {
        const { id, ...params } = this.invoiceItemTemplate
        const payload = {
          tableName: 'invoiceItemTemplates',
          params: {
            ...params,
            netValue: parseAsBasicUnit(params.netValue)
          },
          id
        }
        this.editInvoiceItemTemplate(payload).then(() => {
          this.closeDialog()
          this.getInvoiceItemTemplates()
        })
      }
    }
  },
  computed: {
    ...mapState({
      dialogItem: state => state.layout.dialog.item,
      isProcessing: state => state.invoiceItemTemplates.isProcessing
    })
  }
}
</script>
